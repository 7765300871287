import { createBrowserRouter } from "react-router-dom";
import KarmaBurstPage from "../pages/KarmaBurstPage";
import Terms from '../pages/legal/Terms';
import Privacy from '../pages/legal/Privacy';

import MintKarmaResetPassword from '../pages/mintkarma/account/ResetPassword';
import MintKarmaResetPasswordConfirmation from '../pages/mintkarma/account/ResetPasswordConfirmation';
import MintKarmaVerifyEmailConfirmation from '../pages/mintkarma/account/VerifyEmailConfirmation';
import MintKarmaEmailUnsubscribedConfirmation from '../pages/mintkarma/account/EmailUnsubscribedConfirmation';

import RouteError from '../component/Error/RouteError';

export default createBrowserRouter([
  {
    path: "/",
    element: <KarmaBurstPage />,
    children: [
      {
        path: "legal/terms",
        element: <Terms />,
      },
      {
        path: "legal/privacy",
        element: <Privacy />,
      },
      {
        path: "*",
        element: <RouteError />
      }
    ],
  },
  {
    path: "mintkarma/account/reset-password",
    element: <MintKarmaResetPassword />,
  },
  {
    path: "mintkarma/account/reset-password-confirmation",
    element: <MintKarmaResetPasswordConfirmation />,
  },
  {
    path: "mintkarma/account/verify-email-confirmation",
    element: <MintKarmaVerifyEmailConfirmation />,
  },
  {
    path: "mintkarma/account/email-unsubscribed-confirmation",
    element: <MintKarmaEmailUnsubscribedConfirmation />,
  },

]);
