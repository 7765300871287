export const getLocationPath = function() {
    var path1 = window.location.pathname;
    //var path2 = ReactRouterDome.useLocation().pathname;
    return path1;
}

export const getLocationUrlData = function() {
    var suffixStr = window.location.search;
    //var splitInfo = suffixStr.split('&');
    
    const queryParams = new URLSearchParams(suffixStr);
    var keysIterator = queryParams.keys();
    var objInfo = {};
    for(var curKey of keysIterator) {
        objInfo[curKey] = queryParams.get(curKey);
    }
    return objInfo;
}

export const openUrl1 = function(inputUrl) {
  window.open(inputUrl);
}

export const openUrl2 = function(inputUrl) {
  window.open(inputUrl, "_blank");
}