import React from "react";

const MintKarmaPasswordResetLogo = () => {
  return (
    <svg 
      width="144px" 
      height="144px" 
      viewBox="0 0 144 144" 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      >
        <defs>
            <linearGradient x1="69.1167896%" y1="67.2566136%" x2="32.4435081%" y2="26.6026257%" id="linearGradient-ntyoc962ke-1">
                <stop stop-color="#B0EDC4" offset="0%"></stop>
                <stop stop-color="#E3E4B4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="71.1081582%" y1="60.1399345%" x2="-7.06960887%" y2="7.21388463%" id="linearGradient-ntyoc962ke-2">
                <stop stop-color="#A8E2BB" offset="0%"></stop>
                <stop stop-color="#D8D9AC" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="294.856318%" y1="12.0788811%" x2="16.0421715%" y2="55.2535508%" id="linearGradient-ntyoc962ke-3">
                <stop stop-color="#B0EDC4" offset="0%"></stop>
                <stop stop-color="#E3E4B4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="301.360378%" y1="8.45583111%" x2="13.1329805%" y2="55.5723473%" id="linearGradient-ntyoc962ke-4">
                <stop stop-color="#A8E2BB" offset="0%"></stop>
                <stop stop-color="#D8D9AC" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="-122.964514%" y1="-166.501322%" x2="73.2716366%" y2="77.2153045%" id="linearGradient-ntyoc962ke-5">
                <stop stop-color="#B0EDC4" offset="0%"></stop>
                <stop stop-color="#E3E4B4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="-85.3661805%" y1="-178.265503%" x2="77.5909361%" y2="77.6272415%" id="linearGradient-ntyoc962ke-6">
                <stop stop-color="#A8E2BB" offset="0%"></stop>
                <stop stop-color="#D8D9AC" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="30.7956592%" y1="79.8387301%" x2="59.5444847%" y2="27.9036993%" id="linearGradient-ntyoc962ke-7">
                <stop stop-color="#B0EDC4" offset="0%"></stop>
                <stop stop-color="#E3E4B4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="37.8744583%" y1="45.8683227%" x2="83.0917811%" y2="66.8055861%" id="linearGradient-ntyoc962ke-8">
                <stop stop-color="#A8E2BB" offset="0%"></stop>
                <stop stop-color="#D8D9AC" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="38.0789584%" y1="-1.59478712%" x2="63.9991299%" y2="75.6901446%" id="linearGradient-ntyoc962ke-9">
                <stop stop-color="#B0EDC4" offset="0%"></stop>
                <stop stop-color="#E3E4B4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="72.591216%" y1="69.7772706%" x2="54.7425073%" y2="46.1034899%" id="linearGradient-ntyoc962ke-10">
                <stop stop-color="#A8E2BB" offset="0%"></stop>
                <stop stop-color="#D8D9AC" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="MintKarma-Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="icon_confirm_success">
                <rect id="Rectangle" x="0" y="0" width="144" height="144"></rect>
                <g transform="translate(15, 2)">
                    <path d="M46.4598796,127.318166 C18.0037473,127.318166 25.3424289,131.632623 25.3424289,131.632623 C25.3424289,131.632623 18.9023413,131.929789 18.9023413,134.608446 C18.9023413,137.285714 33.2801264,139.962982 54.3975771,139.962982 C75.5150278,139.962982 76.2638327,135.499943 76.2638327,135.499943 C76.2638327,135.499943 98.8788933,135.499943 98.8788933,131.335457 C98.8788933,127.169583 65.6302128,127.318166 46.4598796,127.318166 Z" id="Path" fill="#0A6B25" fill-rule="nonzero"></path>
                    <g id="Group" opacity="0.4" transform="translate(32.4363, 128.6637)" fill="#113A1D" fill-rule="nonzero">
                        <path d="M33.3637949,4.97683091 C43.0731306,4.97683091 50.9441459,3.8631539 50.9441459,2.48841546 C50.9441459,1.11367701 43.0731306,0 33.3637949,0 C23.6544591,0 15.7834438,1.11367701 15.7834438,2.48841546 C15.7834438,3.8631539 23.6544591,4.97683091 33.3637949,4.97683091 Z" id="Path"></path>
                        <path d="M10.8945926,8.02347349 C16.9114243,8.02347349 21.7890448,7.33193839 21.7890448,6.48071146 C21.7890448,5.62948452 16.9114243,4.93794942 10.8945926,4.93794942 C4.87762054,4.93794942 0,5.62948452 0,6.48071146 C0,7.33193839 4.87762054,8.02347349 10.8945926,8.02347349 Z" id="Path"></path>
                    </g>
                    <path d="M85.0669937,136.541411 C73.3329085,137.232946 78.7508647,140.497603 88.3085863,139.93521 C97.8661676,139.371428 99.0722012,135.716568 85.0669937,136.541411 Z" id="Path" fill="#0A6B25" fill-rule="nonzero"></path>
                    <path d="M92.0388535,132.020049 C92.0388535,132.020049 93.149286,124.841832 97.3147417,122.064582 C101.479636,119.287333 96.7856367,126.023968 96.7856367,126.023968 C96.7856367,126.023968 99.9334533,124.077116 100.375801,125.944816 C100.81829,127.812516 95.7629438,130.820277 92.0388535,132.020049 Z" id="Path" fill="#113A1D" fill-rule="nonzero"></path>
                    <path d="M29.6359142,130.46479 C29.6359142,130.46479 28.7993696,123.727321 24.9852936,121.01742 C21.1712175,118.307658 25.368821,124.731991 25.368821,124.731991 C25.368821,124.731991 22.4823983,122.82541 22.0167466,124.558274 C21.551095,126.290584 26.1902041,129.241411 29.6359142,130.46479 Z" id="Path" fill="#113A1D" fill-rule="nonzero"></path>
                    <path d="M85.5734969,139.014552 C85.5734969,139.014552 85.8348908,134.804242 88.6832696,133.616968 C91.531508,132.429693 91.4326781,135.418014 85.5734969,139.014552 Z" id="Path" fill="#113A1D" fill-rule="nonzero"></path>
                    <path d="M86.1437342,95.4295107 L86.1456996,95.4297884 C86.147665,95.4300662 86.147665,95.4300662 86.147665,95.4300662 C88.7044675,94.9854285 92.3815293,93.9561799 96.6731896,91.6001004 C100.446133,89.5307719 102.380897,87.4857443 103.032276,85.9095164 C103.821231,84.0090446 102.745894,82.7962198 100.791196,83.057559 C100.791196,83.057559 93.1614994,84.5565793 86.7560865,83.0375628 C86.4478046,82.9653543 86.1583341,82.9281392 85.8879557,82.9241122 C85.8879557,82.9241122 85.8879557,82.9241122 85.8855692,82.9257785 C85.805691,82.9453581 83.6131834,83.567462 82.6080384,86.1475267 C82.6076173,86.1494707 82.6053711,86.1511371 82.6050904,86.1530812 C82.29372,86.9565394 82.0959198,87.950517 82.1144505,89.1752839 C82.1892748,94.3133342 86.0040528,95.3916013 86.1437342,95.4295107 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-1)" fill-rule="nonzero"></path>
                    <path d="M86.1438746,95.4295107 L86.14584,95.4297884 C86.1478053,95.4300662 86.1478053,95.4300662 86.1478053,95.4300662 C88.7046078,94.9854285 92.3816697,93.9561799 96.67333,91.6001004 C100.446274,89.5307719 102.380897,87.4857443 103.032276,85.9095164 C99.018294,88.7368951 90.0813477,90.5823773 82.4404207,89.2261076 C82.3321851,89.2072223 82.2235283,89.1902811 82.1145908,89.1752839 C82.1894152,94.3133342 86.0041931,95.3916013 86.1438746,95.4295107 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-2)" fill-rule="nonzero"></path>
                    <path d="M26.8038199,107.647464 C23.970041,107.994759 20.9463234,104.90618 19.3154896,101.591811 C17.9480151,98.8165052 17.5570474,95.883591 19.0667303,94.6795145 C20.8615318,93.2460372 24.9254903,91.7517382 28.2747569,90.6818029 C31.1051667,89.7771139 33.4243001,89.1770892 33.4243001,89.1770892 L41.1720588,100.404398 C41.1720588,100.404398 32.0128856,107.011474 26.8038199,107.647464 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-3)" fill-rule="nonzero"></path>
                    <path d="M26.8033987,107.647464 C23.9696198,107.994759 20.9459022,104.90618 19.3150685,101.591811 C24.5248361,100.438002 29.3874356,98.2446696 29.3874356,98.2446696 C29.3874356,98.2446696 28.2845838,95.7636138 28.2743358,90.6818029 C31.1047455,89.7771139 33.4238789,89.1770892 33.4238789,89.1770892 L41.1716377,100.404398 C41.1716377,100.404398 32.0124644,107.011474 26.8033987,107.647464 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-4)" fill-rule="nonzero"></path>
                    <path d="M67.1039575,121.382212 C68.9964659,123.505975 73.3211163,123.179509 76.7523669,121.767972 C79.6269973,120.587085 81.8746756,118.646066 81.545617,116.750871 C81.1559127,114.4963 79.1060346,110.703827 77.3088466,107.699399 C75.7906003,105.160021 74.4523256,103.185257 74.4523256,103.185257 L61.1626521,106.504904 C61.1626521,106.504904 63.6238536,117.480038 67.1039575,121.382212 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-5)" fill-rule="nonzero"></path>
                    <path d="M67.1039575,121.382212 C68.9964659,123.505975 73.3211163,123.179509 76.7523669,121.767972 C73.6166233,117.477122 71.4340829,112.635682 71.4340829,112.635682 C71.4340829,112.635682 73.9142361,111.501314 77.3088466,107.699399 C75.7906003,105.160021 74.4523256,103.185257 74.4523256,103.185257 L61.1626521,106.504904 C61.1626521,106.504904 63.6238536,117.480038 67.1039575,121.382212 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-6)" fill-rule="nonzero"></path>
                    <path d="M30.6282843,69.3435015 L30.6271612,69.3418351 C30.6261786,69.3401688 30.6261786,69.3401688 30.6261786,69.3401688 C29.997261,66.8387003 29.0609039,59.2716677 29.4861251,54.4192576 C29.8582815,50.1542357 30.9546757,47.5681999 32.1399325,46.3363509 C33.5684738,44.848995 35.1204121,45.3320976 35.6738034,47.2129897 C35.6738034,47.2129897 37.3920959,54.7476673 41.3900742,59.9457061 C41.5816975,60.196214 41.733452,60.4438058 41.8468817,60.6877872 C41.8468817,60.6877872 41.8468817,60.6877872 41.8463202,60.6904256 C41.8607797,60.7711047 42.1783269,63.0138724 40.2128192,64.9737773 C40.2111346,64.9748882 40.2105731,64.9776654 40.2088885,64.9787763 C39.5959746,65.5874105 38.7618166,66.1706329 37.6275189,66.6509582 C32.8699261,68.6690465 30.7198143,69.4548692 30.6282843,69.3435015 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-7)" fill-rule="nonzero"></path>
                    <path d="M30.6282843,69.3435015 L30.6271612,69.3418351 C30.6261786,69.3401688 30.6261786,69.3401688 30.6261786,69.3401688 C29.997261,66.8387003 29.0609039,59.2716677 29.4861251,54.4192576 C29.8582815,50.1542357 30.9546757,47.5681999 32.1399325,46.3363509 C31.1683392,51.1283559 33.0986106,59.990281 37.4485301,66.3758717 C37.5098776,66.4665488 37.5695405,66.5581981 37.6275189,66.6509582 C32.8699261,68.6690465 30.7198143,69.4548692 30.6282843,69.3435015 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-8)" fill-rule="nonzero"></path>
                    <path d="M94.1693117,47.5082113 C105.08047,52.4356072 101.097231,48.5135756 101.097231,48.5135756 C101.097231,48.5135756 109.784717,51.8611332 110.47821,50.7238495 C111.154858,49.6176711 108.254538,48.5875893 108.112751,48.535377 C108.313499,48.545514 114.086063,48.8361531 113.999026,47.2870034 C113.983583,46.9898377 113.750547,46.6229631 113.219898,46.1740207 C109.881581,43.3490026 103.553098,43.5371613 103.553098,43.5371613 C103.553098,43.5371613 106.892819,41.5304597 102.910142,42.3289189 C99.5132858,43.0104559 95.5488592,46.3010799 94.4589226,47.2499271 C94.2710896,47.4147569 94.1693117,47.5082113 94.1693117,47.5082113 Z" id="Path" fill="#113A1D" fill-rule="nonzero"></path>
                    <path d="M94.1693117,47.5082113 C105.08047,52.4356072 101.097231,48.5135756 101.097231,48.5135756 C101.097231,48.5135756 109.784717,51.8611332 110.47821,50.7238495 C111.154858,49.6175322 108.254538,48.5875893 108.112751,48.535377 C108.313499,48.545514 114.086063,48.8361531 113.999026,47.2870034 C111.2433,46.509929 103.802981,44.9395334 94.4589226,47.2499271 C94.2710896,47.4147569 94.1693117,47.5082113 94.1693117,47.5082113 Z" id="Path" fill="#042B0F" fill-rule="nonzero"></path>
                    <path d="M94.8389404,47.261175 C94.8389404,47.261175 92.2310383,56.9707166 92.9945836,57.80528 C93.7565845,58.6388714 95.0628519,57.0518123 95.0628519,57.0518123 C95.0628519,57.0518123 94.3303314,65.6278195 95.050077,66.2936651 C95.7696822,66.9593717 96.9451122,64.6359249 96.9451122,64.6359249 C96.6501668,68.5547627 99.2217095,75.1619778 99.8066871,75.351664 C100.391524,75.541489 101.561058,72.2464214 101.561058,72.2464214 C101.561058,72.2464214 106.129133,82.4421205 108.941012,82.3196438 C109.460431,82.2989533 109.922292,81.9209697 110.283078,81.0551622 C112.555884,75.5930069 110.385557,66.8580021 110.333616,66.6552629 C110.406615,66.7517724 112.739787,69.8406291 112.460424,65.7982038 C112.178253,61.6914851 107.021972,56.5042775 107.021972,56.5042775 C107.021972,56.5042775 110.754766,57.9031781 107.423468,54.8218199 C104.094978,51.7414337 94.8389404,47.261175 94.8389404,47.261175 Z" id="Path" fill="#113A1D" fill-rule="nonzero"></path>
                    <path d="M94.8389404,47.261175 C94.8389404,47.261175 92.2310383,56.9707166 92.9945836,57.80528 C93.7565845,58.6388714 95.0628519,57.0518123 95.0628519,57.0518123 C95.0628519,57.0518123 94.3303314,65.6278195 95.050077,66.2936651 C95.7696822,66.9593717 96.9451122,64.6359249 96.9451122,64.6359249 C96.6501668,68.5547627 99.2217095,75.1619778 99.8066871,75.351664 C100.391524,75.541489 101.561058,72.2464214 101.561058,72.2464214 C101.561058,72.2464214 106.129133,82.4421205 108.941012,82.3196438 C107.026183,63.6743023 94.8389404,47.261175 94.8389404,47.261175 Z" id="Path" fill="#042B0F" fill-rule="nonzero"></path>
                    <path d="M63.888757,113.03505 C45.3172993,113.405813 31.4578097,100.970123 28.5598756,86.2265394 C27.4022742,80.345575 27.9930075,74.0984302 30.7230431,68.1490065 C34.6456355,59.607576 42.839673,53.5049871 49.4798069,49.7215402 C55.6906484,46.182769 62.5708378,43.991797 69.6829406,43.1682037 C91.4469972,40.6474333 99.6679883,49.5771233 95.7163365,48.3281942 C93.0854116,47.4966857 79.5208675,51.5084225 86.3154231,70.5335529 C87.7610911,74.5802829 88.6598255,78.8076731 88.9460673,82.9600775 C90.0192982,98.3250709 82.7392969,112.659705 63.888757,113.03505 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-9)" fill-rule="nonzero"></path>
                    <path d="M63.8894589,113.03505 C45.3180012,113.405813 31.4585116,100.970123 28.5605775,86.2265394 C42.213142,110.423602 71.7538762,108.561179 80.2546514,98.6718105 C85.432552,92.6455959 87.8447596,86.7436632 88.9467692,82.9600775 C90.0200001,98.3250709 82.7399988,112.659566 63.8894589,113.03505 Z" id="Path" fill="url(#linearGradient-ntyoc962ke-10)" fill-rule="nonzero"></path>
                    <path d="M74.258035,70.4913387 C81.8022378,76.7683389 78.0363133,95.9227502 65.9734502,89.4721719 C50.5102209,81.2031896 64.4053678,62.2938705 74.258035,70.4913387 Z" id="Path" fill="#FFFEFF" fill-rule="nonzero"></path>
                    <path d="M52.5606605,59.5353675 C59.6547943,63.043589 52.0594919,78.755322 41.2301776,75.7300642 C30.4008632,72.7048064 41.748473,54.1884681 52.5606605,59.5353675 Z" id="Path" fill="#FFFEFF" fill-rule="nonzero"></path>
                    <path d="M55.2776405,79.533785 C55.8984158,79.5596134 56.2191918,80.2965565 55.8099742,80.7609126 C55.1670184,81.4907737 54.0647281,82.1585634 52.3020743,81.6392177 C50.0383923,80.9722613 49.5543504,79.3724268 49.5454841,78.1511313 C49.5407333,77.5015327 50.3058227,77.1603476 50.7987087,77.5872109 C52.1967868,78.7978139 53.6862545,79.4675476 55.2776405,79.533785 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M49.1267427,72.0634007 C50.0025946,71.0277644 50.0128426,69.6044241 49.1497655,68.8842833 C48.2866885,68.1640037 46.8769586,68.4196495 46.0012471,69.4552858 C45.1253952,70.4909221 45.1151472,71.9142624 45.9782242,72.6344032 C46.8413012,73.3546828 48.2508908,73.099037 49.1267427,72.0634007 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M67.7355424,80.7007852 C68.2649281,79.4172793 67.8267916,78.0240722 66.7572106,77.5888772 C65.6874892,77.1535434 64.3913295,77.8411903 63.8620842,79.1246961 C63.3328388,80.4080631 63.7709753,81.8012702 64.8405563,82.2366041 C65.9101373,82.671799 67.206297,81.9841521 67.7355424,80.7007852 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M43.0188022,43.7276806 C54.5427335,43.7276806 63.8846859,34.4480572 63.8846859,23.0012072 C63.8846859,11.5542184 54.5427335,2.27459502 43.0188022,2.27459502 C31.4948709,2.27459502 22.1529185,11.5542184 22.1529185,23.0012072 C22.1529185,34.4480572 31.4948709,43.7276806 43.0188022,43.7276806 Z" id="Path" fill="#939DC5" fill-rule="nonzero" opacity="0.2"></path>
                    <path d="M40.7292897,41.4531133 C52.2532209,41.4531133 61.5951733,32.1734899 61.5951733,20.7265011 C61.5951733,9.27965117 52.2532209,0 40.7292897,0 C29.2053584,0 19.863406,9.27965117 19.863406,20.7265011 C19.863406,32.1734899 29.2053584,41.4531133 40.7292897,41.4531133 Z" id="Path" fill="#FFFFFF" fill-rule="nonzero"></path>
                    <path d="M40.7298512,38.453684 C50.5861683,38.453684 58.5762287,30.5168607 58.5762287,20.7265011 C58.5762287,10.9361416 50.5861683,2.99945712 40.7298512,2.99945712 C30.8736745,2.99945712 22.883614,10.9361416 22.883614,20.7265011 C22.883614,30.5168607 30.8736745,38.453684 40.7298512,38.453684 Z" id="Path" fill="#B1BCED" fill-rule="nonzero"></path>
                    <polyline id="Path" stroke="#FFFFFF" stroke-width="11.1089976" stroke-linecap="round" stroke-linejoin="round" points="32.6199033 19.221232 38.2626133 24.8262767 48.8375529 14.3220252"></polyline>
                    <path d="M40.7299916,4.56929733 C40.7299916,5.33887314 40.143891,5.98958267 39.3723439,6.0594305 C32.2773679,6.70222487 26.6124773,12.3315705 25.9654503,19.3767579 C25.8949779,20.1431399 25.239949,20.7253902 24.4651732,20.7253902 C23.5810386,20.7253902 22.8781391,19.9730334 22.958298,19.0984775 C23.7363027,10.6081484 30.5422293,3.84526842 39.0919983,3.07249877 C39.9726232,2.99293058 40.7299916,3.69099221 40.7299916,4.56929733 Z" id="Path" fill="#D8DDF6" fill-rule="nonzero"></path>
                    <path d="M40.7290089,36.8837049 C40.7290089,36.1141291 41.3151095,35.4634196 42.0866566,35.3935717 C49.1816326,34.7507774 54.8465231,29.1214317 55.4935501,22.0762443 C55.5640226,21.3098624 56.2190514,20.727612 56.9938273,20.727612 C57.8779618,20.727612 58.5808614,21.4799689 58.5007025,22.3546636 C57.7226978,30.8448538 50.9167712,37.6077338 42.3670022,38.3805035 C41.4863772,38.4602105 40.7290089,37.76201 40.7290089,36.8837049 Z" id="Path" fill="#939DC5" fill-rule="nonzero" opacity="0.5"></path>
                    <polygon id="Path" fill="#E2CE12" fill-rule="nonzero" points="13.5665095 24.3889988 15.5994012 28.0809352 19.3160512 30.1001344 15.5994012 32.1193336 13.5665095 35.8112701 11.5337581 32.1193336 7.81696775 30.1001344 11.5337581 28.0809352"></polygon>
                    <polygon id="Path" fill="#E2CE12" fill-rule="nonzero" points="14.8656173 41.4531133 16.0087591 43.5292461 18.0989273 44.6648634 16.0087591 45.8003418 14.8656173 47.8764746 13.7224754 45.8003418 11.6323072 44.6648634 13.7224754 43.5292461"></polygon>
                    <polygon id="Path" fill="#E2CE12" fill-rule="nonzero" points="77.2320569 11.3227347 78.3751987 13.3990063 80.4653669 14.5344847 78.3751987 15.6699632 77.2320569 17.7462348 76.088915 15.6699632 73.9987469 14.5344847 76.088915 13.3990063"></polygon>
                    <polygon id="Path" fill="#E2CE12" fill-rule="nonzero" points="70.6146651 3.80819214 72.0715638 6.45393877 74.7351981 7.90102457 72.0715638 9.34811036 70.6146651 11.9939959 69.1579068 9.34811036 66.4942725 7.90102457 69.1579068 6.45393877"></polygon>
                    <polygon id="Path" fill="#E2CE12" fill-rule="nonzero" points="3.9082733 33.6886183 5.29020729 36.1982798 7.81668698 37.5707964 5.29020729 38.9434519 3.9082733 41.4531133 2.52647969 38.9434519 0 37.5707964 2.52647969 36.1982798"></polygon>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default MintKarmaPasswordResetLogo;
