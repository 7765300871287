import React from "react";

const MintKarmaEmailUnsubscribedConfirmationIcon = () => {
  return (
    <svg width="128px" height="128px" viewBox="0 0 128 128" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="115.417269%" y1="16.0171478%" x2="60.2239928%" y2="44.2357702%" id="linearGradient-_9_6pq-0kh-1">
                <stop stop-color="#D0F7DB" offset="0%"></stop>
                <stop stop-color="#89EBA4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="92.1838785%" y1="-5.01778234%" x2="20.3211937%" y2="77.4876258%" id="linearGradient-_9_6pq-0kh-2">
                <stop stop-color="#89EBA4" stop-opacity="0" offset="0%"></stop>
                <stop stop-color="#23924B" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="38.3322878%" y1="25.0555864%" x2="80.0733079%" y2="83.6587132%" id="linearGradient-_9_6pq-0kh-3">
                <stop stop-color="#D0F7DB" offset="0%"></stop>
                <stop stop-color="#89EBA4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="49.8453467%" y1="41.9097701%" x2="55.0900584%" y2="68.4280463%" id="linearGradient-_9_6pq-0kh-4">
                <stop stop-color="#89EBA4" stop-opacity="0" offset="0%"></stop>
                <stop stop-color="#23924B" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="-309.53644%" y1="108.190077%" x2="176.872938%" y2="49.5487692%" id="linearGradient-_9_6pq-0kh-5">
                <stop stop-color="#D0F7DB" offset="0%"></stop>
                <stop stop-color="#89EBA4" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="33.0249172%" y1="34.564866%" x2="78.3288648%" y2="80.0623325%" id="linearGradient-_9_6pq-0kh-6">
                <stop stop-color="#89EBA4" stop-opacity="0" offset="0%"></stop>
                <stop stop-color="#23924B" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="49.1529004%" y1="78.9519505%" x2="52.7871215%" y2="30.9488853%" id="linearGradient-_9_6pq-0kh-7">
                <stop stop-color="#A2EFB7" offset="0%"></stop>
                <stop stop-color="#D0F7DB" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="33.0251464%" y1="34.5494038%" x2="78.325624%" y2="80.0467151%" id="linearGradient-_9_6pq-0kh-8">
                <stop stop-color="#89EBA4" stop-opacity="0" offset="0%"></stop>
                <stop stop-color="#23924B" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="48.0388336%" y1="95.7026264%" x2="51.6345066%" y2="48.2111244%" id="linearGradient-_9_6pq-0kh-9">
                <stop stop-color="#A2EFB7" offset="0%"></stop>
                <stop stop-color="#D0F7DB" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="48.628242%" y1="47.6348087%" x2="50.9728357%" y2="54.6885224%" id="linearGradient-_9_6pq-0kh-10">
                <stop stop-color="#D0F7DB" offset="0%"></stop>
                <stop stop-color="#89EBA4" stop-opacity="0" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="67.1290476%" y1="27.9635044%" x2="16.3966308%" y2="63.1860565%" id="linearGradient-_9_6pq-0kh-11">
                <stop stop-color="#B1DAF2" offset="0%"></stop>
                <stop stop-color="#3BA3DE" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="83.1355925%" y1="19.1570294%" x2="37.6130782%" y2="46.726144%" id="linearGradient-_9_6pq-0kh-12">
                <stop stop-color="#B1DAF2" offset="0%"></stop>
                <stop stop-color="#3BA3DE" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="61.7122866%" y1="35.3589288%" x2="46.406253%" y2="46.4602961%" id="linearGradient-_9_6pq-0kh-13">
                <stop stop-color="#F4FAFD" offset="0%"></stop>
                <stop stop-color="#B1DAF2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="50.8355877%" y1="41.4797139%" x2="47.5543203%" y2="64.1758432%" id="linearGradient-_9_6pq-0kh-14">
                <stop stop-color="#B1DAF2" offset="0%"></stop>
                <stop stop-color="#3BA3DE" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="54.4442955%" y1="24.1100619%" x2="44.5387915%" y2="62.4589282%" id="linearGradient-_9_6pq-0kh-15">
                <stop stop-color="#F4FAFD" offset="0%"></stop>
                <stop stop-color="#B1DAF2" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="46.3613846%" y1="32.7330696%" x2="54.6350494%" y2="58.1861517%" id="linearGradient-_9_6pq-0kh-16">
                <stop stop-color="#B1DAF2" offset="0%"></stop>
                <stop stop-color="#3BA3DE" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g id="MintKarma-Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="email_unsubscribed">
                <rect id="Rectangle-Copy-9" x="0" y="0" width="128" height="128"></rect>
                <g transform="translate(13.3333, 4.4444)" id="Path">
                    <path d="M49.417263,113.619553 C72.8781518,113.619553 91.8969074,111.762664 91.8969074,109.471998 C91.8969074,107.181331 72.8781518,105.324442 49.417263,105.324442 C25.9563741,105.324442 6.93752962,107.181331 6.93752962,109.471998 C6.93752962,111.762664 25.9563741,113.619553 49.417263,113.619553 Z" fill="#BFBFBF" fill-rule="nonzero"></path>
                    <path d="M84.6089074,117.633775 C90.3457963,117.633775 94.996463,116.772442 94.996463,115.709331 C94.996463,114.647109 90.3457963,113.784886 84.6089074,113.784886 C78.8720185,113.784886 74.2217963,114.647109 74.2217963,115.709331 C74.2217963,116.772442 78.8720185,117.633775 84.6089074,117.633775 Z" fill="#BFBFBF" fill-rule="nonzero"></path>
                    <path d="M63.515663,119.111109 C65.4083741,119.111109 66.9427741,118.826664 66.9427741,118.477331 C66.9427741,118.127109 65.4083741,117.842664 63.515663,117.842664 C61.6229518,117.842664 60.0885518,118.127109 60.0885518,118.477331 C60.0885518,118.826664 61.6229518,119.111109 63.515663,119.111109 Z" fill="#BFBFBF" fill-rule="nonzero"></path>
                    <path d="M15.2029518,107.247109 C23.2459741,107.247109 29.7661518,106.385775 29.7661518,105.322664 C29.7661518,104.259553 23.2459741,103.39822 15.2029518,103.39822 C7.15992962,103.39822 0.639751839,104.259553 0.639751839,105.322664 C0.639751839,106.385775 7.15992962,107.247109 15.2029518,107.247109 Z" fill="#BFBFBF" fill-rule="nonzero"></path>
                    <path d="M67.965263,106.687109 C67.965263,106.687109 89.1875741,116.060442 92.1191296,109.092442 C95.0497963,102.125331 84.0489074,96.0151087 84.0489074,96.0151087 L67.965263,106.687109 Z" fill="url(#linearGradient-_9_6pq-0kh-1)" fill-rule="nonzero"></path>
                    <path d="M67.965263,106.687109 C67.965263,106.687109 89.1875741,116.060442 92.1191296,109.092442 C95.0497963,102.125331 84.0489074,96.0151087 84.0489074,96.0151087 L67.965263,106.687109 Z" fill="url(#linearGradient-_9_6pq-0kh-2)" fill-rule="nonzero"></path>
                    <path d="M84.3600185,47.9190198 C84.3226852,47.8702198 84.2862407,47.8214198 84.2497963,47.7704865 C90.9573518,38.2576865 100.300463,1.16449536 67.1992185,0.0316598056 C36.5194852,-1.01915797 17.4330852,24.2735087 5.72001851,48.2847976 C1.27406295,57.4029309 -1.05695927,68.8864865 0.465529617,79.6273754 C2.95095184,97.1742198 15.7195741,112.73422 46.3453518,112.73422 C74.9873963,112.73422 88.6533518,99.3377754 92.892463,83.0303087 C96.1786852,64.7962643 85.548463,49.5550198 84.3582407,47.9190198 L84.3600185,47.9190198 Z" fill="url(#linearGradient-_9_6pq-0kh-3)" fill-rule="nonzero"></path>
                    <path d="M93.1351296,70.2491531 C90.1440185,86.9511087 75.5387741,99.1955531 49.5307741,99.1955531 C23.5226852,99.1955531 6.86001851,90.6666643 0.467040728,79.6266643 C2.95246295,97.1733309 15.7210852,112.73422 46.3467741,112.73422 C74.9888185,112.73422 88.6551296,99.3368865 92.8942407,83.0295976 C93.7049074,78.5337754 93.668463,74.2218643 93.1351296,70.2469309 L93.1351296,70.2491531 Z" fill="url(#linearGradient-_9_6pq-0kh-4)" fill-rule="nonzero"></path>
                    <path d="M35.8673074,114.786664 C35.8673074,114.786664 50.0465074,122.418664 53.2073074,115.586664 C56.3681074,108.751998 45.5368185,100.258664 45.5368185,100.258664 C35.8170852,93.0559976 25.0630407,107.999998 35.8673074,114.786664 Z" fill="url(#linearGradient-_9_6pq-0kh-5)" fill-rule="nonzero"></path>
                    <path d="M89.3937963,16.0121754 C89.3937963,16.0121754 101.618685,18.2445309 97.4426852,36.2081754 C97.4426852,36.2081754 85.2995741,30.2535087 89.3937963,16.0121754 Z" fill="#0A6C25" fill-rule="nonzero"></path>
                    <path d="M84.2222407,13.6797309 C84.2222407,13.6797309 86.5057963,13.5600865 89.3920185,16.0097754 C90.3555741,16.8255976 93.5369074,20.567642 95.3920185,27.8457754" stroke="#074819" stroke-width="1.38970667" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M53.5737963,31.1977754 C58.0622407,29.2823976 60.9914852,32.2928865 60.9914852,32.2928865 C60.9914852,32.2928865 60.5282852,27.1541309 54.2839296,27.843642" stroke="#000000" stroke-width="1.38970667" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M82.8551296,37.8641754 C80.2497963,34.4656865 77.0640185,36.1527087 77.0640185,36.1527087 C77.0640185,36.1527087 79.1920185,31.6125309 83.5049074,34.5299531" stroke="#000000" stroke-width="1.38970667" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M68.7758407,32.8999087 C63.5830407,32.7314643 62.2921074,38.4288865 64.9184185,38.1162643 C67.5446407,37.8037309 69.2678407,36.8592865 72.2241074,39.1227531 C75.1803741,41.3862198 74.6053518,33.0883531 68.7777074,32.8999087 L68.7758407,32.8999087 Z" fill="#000000" fill-rule="nonzero"></path>
                    <path d="M81.9991296,50.5101309 C80.2915741,51.780442 75.7991296,50.5101309 75.7991296,50.5101309 C75.7991296,50.5101309 79.4809074,43.3561754 78.7129074,36.592442 C80.172463,37.0735087 82.0631296,38.0688865 82.7751296,40.079642 C84.0009074,43.5358198 81.9991296,50.5101309 81.9991296,50.5101309 Z" fill="url(#linearGradient-_9_6pq-0kh-6)" fill-rule="nonzero"></path>
                    <path d="M70.6007296,46.8678198 C70.6007296,46.8678198 72.3219741,33.8767976 79.3591296,35.925242 C86.3973518,37.9736865 82.3893518,51.0733309 82.3893518,51.0733309 C82.3893518,51.0733309 83.7920185,39.2661309 78.6569074,37.9603531 C73.8927296,36.7499531 70.6007296,46.8678198 70.6007296,46.8678198 Z" fill="url(#linearGradient-_9_6pq-0kh-7)" fill-rule="nonzero"></path>
                    <path d="M61.6974407,46.6347531 C59.9897074,47.9049754 55.4974407,46.6347531 55.4974407,46.6347531 C55.4974407,46.6347531 59.1792185,39.4807976 58.4112185,32.7169754 C59.870063,33.198042 61.7610852,34.1935087 62.4731741,36.2041754 C63.6985074,39.6603531 61.6974407,46.6347531 61.6974407,46.6347531 Z" fill="url(#linearGradient-_9_6pq-0kh-8)" fill-rule="nonzero"></path>
                    <path d="M58.3322852,33.3917309 C63.4304185,34.8858643 61.6976185,46.6354643 61.6976185,46.6354643 C61.6976185,46.6354643 66.0682852,33.6887976 59.0905963,31.3831976 C52.1129074,29.0775976 50.030863,41.9976865 50.030863,41.9976865 C50.030863,41.9976865 53.6026852,32.0061309 58.3322852,33.3917309 Z" fill="url(#linearGradient-_9_6pq-0kh-9)" fill-rule="nonzero"></path>
                    <path d="M84.1920185,56.3065754 C78.1582407,52.4935087 70.0962852,50.1635531 61.2390407,50.1635531 C50.6393963,50.1635531 41.1782407,53.4999976 34.9705074,58.7230198 C39.2408185,51.9747531 47.9802852,45.2287087 60.1545963,45.2287087 C71.0263296,45.2287087 80.3369074,49.8088865 84.1920185,56.3065754 Z" fill="url(#linearGradient-_9_6pq-0kh-10)" fill-rule="nonzero"></path>
                    <path d="M87.5973518,46.4566198 C87.5973518,46.4566198 91.6320185,49.9792865 93.7973518,56.5590198 C95.1653518,60.7179531 97.9155741,60.3321754 99.028463,58.0111087 C99.6773518,56.6609754 99.7697963,54.6546643 98.708463,52.5087087 C95.8293518,46.6783087 87.5955741,46.4566198 87.5955741,46.4566198 L87.5973518,46.4566198 Z" fill="url(#linearGradient-_9_6pq-0kh-11)" fill-rule="nonzero"></path>
                    <path d="M87.5973518,46.4566198 C87.5973518,46.4566198 91.6320185,49.9792865 93.7973518,56.5590198 C95.1653518,60.7179531 97.9155741,60.3321754 99.028463,58.0111087 L98.9937963,58.0421309 C98.212463,58.7183087 97.0257963,58.6850643 96.2755741,57.9645309 C95.5706852,57.2883531 95.3120185,56.1976865 94.9955741,55.2067531 C93.660463,51.0344865 91.2791296,47.8177754 87.5955741,46.4566198 L87.5973518,46.4566198 Z" fill="url(#linearGradient-_9_6pq-0kh-12)" fill-rule="nonzero"></path>
                    <path d="M98.4711296,53.4061309 C99.5155741,55.8624865 97.5395741,57.5318198 96.8720185,54.6165309 C96.204463,51.7013309 94.6622407,49.7903976 94.6622407,49.7903976 C94.6622407,49.7903976 97.1751296,50.3490643 98.4711296,53.4061309 Z" fill="url(#linearGradient-_9_6pq-0kh-13)" fill-rule="nonzero"></path>
                    <path d="M92.0960185,42.6143976 C92.0960185,42.6143976 95.0640185,43.4967087 98.4293518,45.2214198 C100.684463,46.3786643 101.862241,44.6495087 101.104019,43.122042 C100.731574,42.3704865 99.8880185,41.6721754 98.4755741,41.388442 C94.1911296,40.5327087 92.0960185,42.6121754 92.0960185,42.6121754 L92.0960185,42.6143976 Z" fill="url(#linearGradient-_9_6pq-0kh-14)" fill-rule="nonzero"></path>
                    <path d="M98.9431296,41.7291531 C100.390241,42.1680865 100.35913,44.0945754 98.9102407,43.2655087 C97.460463,42.4363531 96.3066852,41.6648865 96.3066852,41.6648865 C96.3066852,41.6648865 97.244463,41.214842 98.9431296,41.7291531 Z" fill="url(#linearGradient-_9_6pq-0kh-15)" fill-rule="nonzero"></path>
                    <path d="M92.0960185,42.6137754 C92.0960185,42.6137754 95.0640185,43.4960865 98.4293518,45.2208865 C100.685352,46.378042 101.862241,44.6488865 101.104019,43.1214198 C101.329796,44.3739531 99.9635741,45.351642 98.2613518,44.1544865 C96.3662407,42.8221309 93.9502407,42.3432865 92.0942407,42.6115531 L92.0960185,42.6137754 Z" fill="url(#linearGradient-_9_6pq-0kh-16)" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
  );
};

export default MintKarmaEmailUnsubscribedConfirmationIcon;
