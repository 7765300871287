/* eslint-disable no-useless-concat */
let CryptoJS = require("crypto-js");

var AES_KEY = "7" + "6" + "f" + "b" + "5" + "c" + "4" + "c" + "7" + "3" + 
"d" + "b" + "f" + "c" + "c" + "4" + "65"; // "76fb5c4c73dbfcc465"

var AES_IV = "f" + "1" + "d" + "9" + "9" + "8" + "1" + "0" + "e" + "0" + 
"8" + "0" + "4" + "a" + "f" + "f"; // "f1d99810e0804aff"
 // ^^ 16 characters
 
 var IV_WORDARRAY = CryptoJS.enc.Utf8.parse(AES_IV);
 
 export const decryptString = function (encryptedStr) {
   const AES_WORDARRAY = CryptoJS.SHA256(AES_KEY);
   let optionObj = createCryptOptionObj();
   let cipherParamsObj = CryptoJS.AES.decrypt(
     encryptedStr,
     AES_WORDARRAY,
     optionObj,
   );
   let msgUtf8 = cipherParamsObj.toString(CryptoJS.enc.Utf8);
   return msgUtf8;
 };
 
 export const encryptString = function (dataStr) {
   const AES_WORDARRAY = CryptoJS.SHA256(AES_KEY);
   let optionObj = createCryptOptionObj();
   let cipherParamsObj = CryptoJS.AES.encrypt(dataStr, AES_WORDARRAY, optionObj);
   let cipherTextObj = cipherParamsObj.ciphertext;
   let encrypted2 = cipherTextObj.toString(CryptoJS.enc.Base64);
   return encrypted2;
 };
 
 function createCryptOptionObj() {
   let optionObj = {};
   optionObj["iv"] = IV_WORDARRAY;
   optionObj["mode"] = CryptoJS.mode.CBC;
   optionObj["padding"] = CryptoJS.pad.Pkcs7;
   return optionObj;
 }
 
 export const decryptToDataObject = function (rawDataStr) {
   let decodedStr = decodeURIComponent(rawDataStr);
   let descryptedString = decryptString(decodedStr);
   let respData = JSON.parse(descryptedString);
   return respData;
 };
 