import toast from "react-hot-toast";

export const showToastReqError = function (errorInfo) {
  if (errorInfo["statusCode"] >= 200 && errorInfo["statusCode"] <= 299) {
    toast.error(errorInfo["errorMsg"]);
  } else {
    toast.error(errorInfo["statusCode"] + ": " + errorInfo["errorMsg"]);
  }
};

export const showToastError = function (msg) {
  toast.error(msg);
};

export const showToastSuccess = function (msg) {
  toast.success(msg);
};
