// Photos from https://citizenofnowhe.re/lines-of-the-city
import { Fragment, useEffect, useRef, useState } from "react";
import NavbarComponent from "../component/PageComponents/NavbarComponent";
import Landing from "../component/PageComponents/Landing";
import AboutSection from "../component/PageComponents/AboutSection";
import AffiliateProgramInfo from "../component/PageComponents/AffiliateProgramInfo";
import Contact from "../component/PageComponents/Contact";
import Footer from '../component/PageComponents/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import RouteError from '../component/Error/RouteError';

export default function KarmaBurstPage() {
  const [selectedTopic, setSelectedTopic] = useState({
    type: true,
    key: 'Choose A Topic',
    label: 'Choose A Topic',
    // labelColor: "#9BDEDC",
    // details: "Choose A Topic",
  })
  const landingRef = useRef(null);
  const aboutRef = useRef(null);
  const affiliateProgramRef = useRef(null);
  const contactRef = useRef(null);

  const location = useLocation();
  console.log("location : ", location);
  const [selectedPage, setSelectedPage] = useState("");

  useEffect(() => {
    setSelectedPage(location.hash);
    const hash = location.hash;
    // If there is a hash and it matches the ID of the target component
    if ((hash && hash === '#landing_page')) {
      // Scroll to the component using the DOM method
      landingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if (hash && hash === '#about_page') {
      // Scroll to the component using the DOM method
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (hash && hash === '#affiliate_program_page') {
      // Scroll to the component using the DOM method
      affiliateProgramRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (hash && hash === '#contact_page') {
      // Scroll to the component using the DOM method
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location])

  return (
    <div>
      <div className="font-light">
        <div className="bg-white-100 w-screen fixed z-50">
          <NavbarComponent
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            aboutRef={aboutRef}
          />
        </div>
        <div className="snap-y snap-mandatory h-screen overflow-scroll">
          {
            location.pathname === "/legal/terms" || location.pathname === "/legal/privacy" ? <Outlet /> : location.pathname === "/" ? <Fragment>
              <Landing landingRef={landingRef} />
              <AboutSection aboutRef={aboutRef} />
              <AffiliateProgramInfo affiliateProgramRef={affiliateProgramRef} setSelectedTopic={setSelectedTopic} />
              <Contact contactRef={contactRef} selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic} />
            </Fragment> : <RouteError />
          }
        </div>
        <Footer />
      </div>
    </div >
  );
}
