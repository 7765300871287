import React from "react";
import { motion } from "framer-motion";
import AboutRightImage from '../Images/KarmaBurstImages/AboutRightImage.svg'
import MintKarmaQRcode from '../Images/KarmaBurstImages/MintKarmaQRcode.svg'
import Googleplay from '../Images/KarmaBurstImages/Googleplay.svg'
// import Appstore from '../Images/KarmaBurstImages/Appstore.svg'

import { Image } from '@nextui-org/react';
export default function AboutSection({ aboutRef }) {
  const content = {
    title: (
      <div>
        <div className='xl:text-[40px] lg:text-4xl text-3xl font-bold tracking-normal' style={{ fontFamily: 'GeneralSans-Medium' }}>
          <div className=''><span className='text-[#38D169]'>MintKarma</span> - Claim</div>
          <div className=''>
            <div className='xl:mt-2 mt-0'>Your Free Reward</div>
          </div>
        </div>
      </div>
    ),
    leftSide: (
      <div className='lg:my-4 my-2'>
        <div>
          <p className='text-wrap xl:text-lg'>
            Play games and earn rewards. Cash out by playing from the greatest selection of games from all genres. Choose from dozens of top brand gift cards like Amazon, iTunes, Google Play and many more.
          </p>
        </div>
        <div className='lg:mt-7 mt-3 flex items-center'>
          <div className='lg:block hidden p-4'><Image src={MintKarmaQRcode} alt='MintKarma QR Code' width={'190'} height={'190'} /></div>
          <div className='lg:block flex lg:gap-0 gap-2 lg:mt-0 mt-4'>
            <div className='lg:block hidden'>Or download from:</div>
            <a href='https://invite.mintkarma.io/f2JGfNrIJMb' target='_blank' rel='noopener noreferrer'>
              <Image className='lg:my-2 my-0' src={Googleplay} alt='Google Play Icon' width={'193'} height={'54'} />
            </a> 
          </div>
        </div>

      </div>
    ),
    rightSide: <div className='w-full'>
      <Image src={AboutRightImage} alt='About Image' width={'624'} height={'624'} />
    </div>,
  };

  return (
    <div
      ref={aboutRef}
      id="about_page"
      className="lg:px-10 px-2 h-screen bg-[#EEFBF2] rounded-lg flex flex-col justify-center md:flex-row lg:container mx-auto snap-start w-screen h-screen"
      style={{
        // background:
        //   "radial-gradient(50.00% 50.00% at 50% 50.00%, #686868 0%, #585858 100%)",
      }}
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
      >
        <div className="w-full">
          <div >{content.title}</div>
          <div className=" mg:mt-4 mt-2 text-slate-700">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center p-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100, duration: 2 }}
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  );
}
