import React, { useState } from 'react'

import { motion } from 'framer-motion'
import FacebookLogo from '../Images/KarmaBurstImages/FacebookLogo.svg'
import EmailLogo from '../Images/KarmaBurstImages/EmailLogo.svg'
import DividerIcon from '../Images/KarmaBurstImages/DividerIcon.svg'

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from '@nextui-org/react'
import { Link, useNavigate } from 'react-router-dom'
export default function Contact({
  contactRef,
  selectedTopic,
  setSelectedTopic,
}) {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const topics = {
    Affiliate_Program: {
      type: true,
      key: 'Affiliate_Program',
      label: 'Affiliate Program',
      // labelColor: "#FF7C6C",
      // details: "1Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.21Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    Business_Inquiry: {
      type: true,
      key: 'Business_Inquiry',
      label: 'Business Inquiry',
      // labelColor: "#EEFBF2",
      // details: 'If you are missing rewards, please apply for missing rewards from inside the app.',
    },
    General_Questions: {
      type: true,
      key: 'General_Questions',
      label: 'General Questions',
      // labelColor: "#FF7C6C",
      // details: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    Missing_Rewards: {
      type: false,
      key: 'Missing_Rewards',
      label: 'Missing Rewards',
      labelColor:
        'lg:my-6 my-2 rounded-xl flex items-center justify-center bg-[#FCEED8]',
      details:
        'If you are missing rewards, please apply for missing rewards from inside the app. Click on the button below for more instrucitons.',
      submit_text: 'Missing Rewards Instructions',
      submit_link: 'https://karma-faq.groovehq.com/help/missing-rewards'
    },
    Delete_My_Data: {
      type: false,
      key: 'Delete_My_Data',
      label: 'Delete My data',
      labelColor:
        'lg:my-6 my-2 rounded-xl flex items-center justify-center bg-[#FCE8E8]',
      details:
        'You can delete your data from inside the app. Click on the button below for more instructions.',
      submit_text: 'Delete Data Instructions',
      submit_link: 'https://karma-faq.groovehq.com/help/delete-account'
    },
  }

  const content = {
    title: (
      <div className="">
        <div className="xl:text-7xl lg:text-5xl text-4xl tracking-wide">
          <div className="">
            <span className="font-extralight">GET</span>
            <span
              className="ms-3 xl:mt-2 mt-1 font-semibold"
              style={{ fontFamily: 'GeneralSans-Medium' }}
            >
              IN TOUCH
            </span>
          </div>
          <div className="font-extralight pt-2">WITH US</div>
        </div>
      </div>
    ),
    leftSide: (
      <div className="lg:pt-2 pt-0">
        <div>
          <p className="font-light text-wrap">
            Have a question or feedback? Please shoot us a message.
          </p>
        </div>
        <div className="lg:mt-8 mt-2 hidden">
          {/* <h5 className="text-lg">Social media :</h5> */}
          <div className="flex lg:flex-row flex-col lg:gap-4 gap-2 mt-2">
            <div className="flex gap-2 items-center">
              <Image
                src={EmailLogo}
                alt="Email logo"
                width={'24'}
                height={'24'}
              />
              <span>support@karmaburst.com</span>
            </div>
            <div className="lg:flex hidden  items-center">
              {' '}
              <Image
                src={DividerIcon}
                alt="Divider logo"
                width={'8'}
                height={'8'}
              />
            </div>
            <div className="flex gap-2 items-center">
              <Image
                src={FacebookLogo}
                alt="Facebook logo"
                width={'24'}
                height={'24'}
              />
              <span>Karma Burst</span>
            </div>
          </div>
        </div>
      </div>
    ),
    rightSide: (
      <div className="lg:p-8 p-4 w-full shadow rounded-xl flex flex-col justify-center ">
        <div
          className="text-xl lg:mb-5 mb-2 "
          style={{ fontFamily: 'GeneralSans-Medium' }}
        >
          Fill out the form
        </div>
        <Dropdown onOpenChange={() => setIsDropdownOpen((pre) => !pre)}>
          <DropdownTrigger>
            <Button
              className="bg-white rounded-md ring-gray-200 hover:ring-1"
              style={{
                borderWidth: '1px',
              }}
            >
              <div className="flex gap-2 items-center w-full justify-between">
                <div
                  className={
                    selectedTopic.label === 'Choose A Topic'
                      ? 'text-gray-400'
                      : 'text-black font-light'
                  }
                >
                  {selectedTopic.label}
                </div>
                <div className="pt-[1px]">
                  {isDropdownOpen ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 15.75 7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m19.5 8.25-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Static Actions"
            onAction={(key) => setSelectedTopic(topics[key])}
            className={'lg:w-96 w-full'}
          >
            {Object.keys(topics).map((topic) => (
              <DropdownItem key={topic} className="">
                <span className="font-light">{topics[topic].label}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {selectedTopic.type ? (
          <div>
            <input
              id="name"
              name="name"
              type="text"
              className="block w-full px-4 my-2 rounded-md border-0 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-200 hover:ring-2 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 font-light"
              placeholder="Name"
              onChange={(e) =>
                setForm((pre) => ({ ...pre, name: e.target.value }))
              }
              value={form.name}
            />
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              className="block w-full px-4 my-2 rounded-md border-0 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-200 hover:ring-2 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 font-light"
              placeholder="Email"
              onChange={(e) =>
                setForm((pre) => ({ ...pre, email: e.target.value }))
              }
              value={form.email}
            />
            <textarea
              id="message"
              name="message"
              type="text"
              rows={3}
              className="block w-full px-4 my-2 rounded-md border-0 py-2 text-black shadow-sm ring-1 ring-inset ring-gray-200 hover:ring-2 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6 font-light"
              placeholder="Message"
              onChange={(e) =>
                setForm((pre) => ({ ...pre, message: e.target.value }))
              }
              value={form.message}
            />
            <Button
              className="mt-3 rounded-full bg-[#6540F2] text-white w-full"
              style={{ fontFamily: 'GeneralSans-Medium' }}
            >
              Submit
            </Button>
          </div>
        ) : (
          <div>
            <div className={selectedTopic.labelColor}>
              <div className="lg:h-44 h-24 overflow-auto my-auto lg:px-4 px-2 py-2 font-light">
                {selectedTopic.details}
              </div>
            </div>
            <Link
              to={selectedTopic.submit_link}
            >
              <Button className="mt-3 rounded-full bg-[#6540F2] text-white w-full"
                style={{ fontFamily: 'GeneralSans-Medium' }}>{selectedTopic.submit_text}</Button>
            </Link>
          </div>
        )}
      </div>
    ),
  }

  return (
    <div
      ref={contactRef}
      id="contact_page"
      className="lg:px-10 px-2 h-screen bg-white-400 flex flex-col justify-center md:flex-row lg:container mx-auto snap-start w-screen h-screen"
      style={
        {
          // background:
          //   "radial-gradient(50.00% 50.00% at 50% 50.00%, #686868 0%, #585858 100%)",
        }
      }
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
      >
        <div className="w-full">
          <div>{content.title}</div>
          <div className="lg:mt-4 mt-1 text-slate-700">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center px-4 pt-0 pb-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 100, duration: 2 }}
          className="w-full"
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  )
}
