import * as RequestUtil from "../http_request/RequestUtil.js";

export const unsubscribeAccount = function(hash, callback) {
    let params = {};
    params["hash"] = hash;
    var fullUrl = RequestUtil.createFullUrl("/app_all/browserweb_unsubscribe", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                let responseInfo = {};
                callback(null, responseInfo);
            }
    });
}