import React from 'react'

import { motion } from 'framer-motion'
import AffiliateProgramInfoLeftImage from '../Images/KarmaBurstImages/AffiliateProgramInfoLeftImage.svg'
import ListIcon from '../Images/KarmaBurstImages/ListIcon.svg'
import { Button, Image } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
export default function AffiliateProgramInfo({
  affiliateProgramRef,
  setSelectedTopic,
}) {
  const navigate = useNavigate()

  const ListOfAffiliatePrograms = [
    'Earn up to 50% of what your referrals earn PLUS automatic PayPal payment when you reach earning milestones.',
    'Cash out ANY time - PayPal or Gift Cards.',
    'Receive your payment or gift cards within 24-48 hrs',
    'Contact us if you have a social channel to promote and we can upgrade you to VIP immediately',
    <div>
      <a className='text-blue-500 hover:text-blue-700 visited:text-purple-500 active:text-gray-700' href={'https://karma-faq.groovehq.com/help/affiliate-referral-program-invite-rewards'}>Details here</a>
    </div>,
  ]
  const content = {
    title: (
      <div>
        <div className="xl:text-7xl lg:text-5xl text-4xl tracking-normal">
          <div
            className="xl:mt-2 mt-1 font-semibold"
            style={{ fontFamily: 'GeneralSans-Medium' }}
          >
            AFFILIATE
          </div>
          <div className="font-extralight">PROGRAM</div>
        </div>
      </div>
    ),
    leftSide: (
      <div className="lg:pt-2 pt-0 font-light">
        <div>
          <p className="text-wrap">
            Top influencers have earned tens of thousands through our affiliate
            program. Get started in less than 2 minutes - install the app,
            create your account, and grab your referral link.
          </p>
        </div>
        <div className="mt-4">
          {ListOfAffiliatePrograms.map((item, index) => (
            <div key={index} className="mb-6 grid grid-cols-12">
              <div className="col-span-1">
                <Image
                  src={ListIcon}
                  alt="mintkarmaIcon"
                  width={'30'}
                  height={'30'}
                />
              </div>
              <div className="text-wrap col-span-11">{item}</div>
            </div>
          ))}
        </div>
        <div>
          <Button
            className="rounded-full bg-[#6540F2] text-white "
            style={{ fontFamily: 'GeneralSans-Medium' }}
            onClick={() => {
              setSelectedTopic({
                type: true,
                key: 'Affiliate_Program',
                lebal: 'Affiliate Program',
              })
              navigate('#contact_page')
            }}
          >
            Contact Us
          </Button>
        </div>
      </div>
    ),
    rightSide: (
      <div className="md:block hidden md:w-full w-72">
        {' '}
        <Image
          src={AffiliateProgramInfoLeftImage}
          alt="Landing Image"
          width={'624'}
          height={'624'}
        />
      </div>
    ),
  }

  return (
    <div
      ref={affiliateProgramRef}
      id="affiliate_program_page"
      className="lg:container mx-auto lg:px-10 px-2 h-screen bg-white-400 flex flex-col justify-center md:flex-row snap-start w-screen h-screen"
      style={
        {
          // background:
          //   "radial-gradient(50.00% 50.00% at 50% 50.00%, #686868 0%, #585858 100%)",
        }
      }
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
      >
        <div className="w-full">
          <div>{content.title}</div>
          <div className=" md:mt-4 mt-2 text-slate-700">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center p-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: 'spring', stiffness: 100, duration: 2 }}
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  )
}
