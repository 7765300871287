import React from "react";

import { motion } from "framer-motion";
import LandingLeftImage from '../Images/KarmaBurstImages/LandingRightImage.svg'
// import MintKarmaIcon from "../Icons/AppIcons/MintKarmaIcon.svg"
import LandingLine from '../Images/KarmaBurstImages/LandingLine.svg'
import { Image } from '@nextui-org/react';
export default function Landing({ landingRef }) {
  const content = {
    title: (
      <div>
        <div className='xl:text-7xl lg:text-5xl text-4xl tracking-normal'>
          <div className='font-light'>WE MAKE</div>
          <div className='relative'>
            <div className='xl:mt-2 mt-1 absolute font-bold' style={{ fontFamily: 'GeneralSans-Medium' }}>REWARDS APPS</div>
            <div className='z-10 xl:pt-14 lg:pt-10 pt-7' ><Image src={LandingLine} alt={"line"} width={"500"} height={"160"} /></div>
          </div>
        </div>
      </div>
    ),
    leftSide: (
      <div className='lg:pt-2 pt-0'>
        <div>
          <p className='text-wrap xl:text-lg'>
            We have over 10 years Experience developing reward apps with millions in payouts and millions of happy users around the world.
          </p>
        </div>
        {/* <div className='lg:mt-7 mt-3'><Image src={MintKarmaIcon} alt='mintkarmaIcon' width={'166'} height={'48'} /></div> */}

      </div>
    ),
    rightSide: <Image src={LandingLeftImage} alt='Lannding Image' width={'624'} height={'624'} />,
  };

  return (
    <div
      ref={landingRef}
      id="landing_page"
      className="lg:px-10 px-2 lg:pt-0 pt-12 h-screen bg-white-400 flex flex-col justify-center md:flex-row lg:container mx-auto snap-start w-screen h-screen"
      style={{
        // background:
        //   "radial-gradient(50.00% 50.00% at 50% 50.00%, #686868 0%, #585858 100%)",
      }}
    >
      <motion.div
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100, duration: 2 }}
        className="md:w-1/2 flex items-center justify-center p-4"
      >
        <div className="w-full">
          <div >{content.title}</div>
          <div className=" mg:mt-4 mt-2">{content.leftSide}</div>
        </div>
      </motion.div>
      <div className="md:w-1/2 flex items-center justify-center p-4">
        <motion.div
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 100, duration: 2 }}
        >
          {content.rightSide}
        </motion.div>
      </div>
    </div>
  );
}
