import * as RequestUtil from "../http_request/RequestUtil.js";

export const changeResetPassword = function(hash, password, callback) {
    let params = {};
    params["hash"] = hash;
    params["password"] = password;
    var fullUrl = RequestUtil.createFullUrl("/app_all/browserweb_password_change", params);
    RequestUtil.requestGET(fullUrl,
        function(errorInfo, respData) {
            if (errorInfo) {
                callback(errorInfo);
            } else {
                let responseInfo = {};
                callback(null, responseInfo);
            }
    });
}